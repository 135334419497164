import Messages from "./Messages";
import MessageInput from "./MessageInput";
import { MinusIcon } from "@radix-ui/react-icons";
import { localization as l } from "@/lib/localization";
import { useGlobalStore } from "@/lib/state/globalStore";
import { useShallow } from "zustand/react/shallow";
import { OmnichannelChatSDK } from "@microsoft/omnichannel-chat-sdk";

type Props = {
    chatSDK: OmnichannelChatSDK;
};

function ChatContainer({ chatSDK }: Props): JSX.Element {
    const { open, set_open } = useGlobalStore(
        useShallow((state) => ({
            open: state.open,
            set_open: state.set_open,
        })),
    );

    const minimize = () => {
        set_open("closed");
    };

    return (
        <div className="chat-main tw-pointer-events-none tw-fixed tw-bottom-0 tw-right-0 tw-z-[1001] tw-w-full tw-max-w-[100svw] sm:tw-bottom-4 sm:tw-right-[15px] sm:tw-w-[370px]">
            <div
                className="chat-card data-[state=open]:tw-duration-750 tw-h-full tw-w-full tw-bg-white tw-opacity-0 tw-transition tw-duration-300 tw-ease-out data-[state=open]:tw-pointer-events-auto data-[state=closed]:tw-translate-y-12 data-[state=open]:tw-translate-y-0 data-[state=open]:tw-opacity-100 data-[state=open]:tw-shadow-2xl data-[state=open]:tw-fade-in sm:tw-rounded"
                data-state={open === "chat" ? "open" : "closed"}
            >
                <div className="tw-flex tw-h-12 tw-items-center sm:tw-rounded-t tw-bg-dpj-forest-green tw-pl-5 tw-pr-1 tw-text-lg tw-font-bold tw-text-white">
                    <div>{l["Chat with DPJ"]}</div>
                    <div
                        onClick={minimize}
                        className="tw-ml-auto tw-h-12 tw-w-10 tw-cursor-pointer tw-p-2 sm:tw-w-8"
                    >
                        <div className="tw-sr-only">{l["Minimize"]}</div>
                        <MinusIcon className="tw-h-full tw-w-full" />
                    </div>
                </div>
                <div className="tw-h-[calc(100svh-118px)] sm:tw-h-96">
                    <Messages chatSDK={chatSDK} />
                </div>
                <MessageInput chatSDK={chatSDK} className="" />
            </div>
        </div>
    );
}
export default ChatContainer;
