import { ChangeEventHandler, KeyboardEventHandler } from "react";
import { Button } from "./ui/button";
import { PaperPlaneIcon, UploadIcon } from "@radix-ui/react-icons";
import { cn, focusInput } from "../lib/utils";
import { OmnichannelChatSDK } from "@microsoft/omnichannel-chat-sdk";
import { localization as l } from "@/lib/localization";
import { useGlobalStore } from "@/lib/state/globalStore";
import { useShallow } from "zustand/react/shallow";
import act from "@/lib/sdkWrangler";

function MessageInput({
    chatSDK,
    className = "",
}: {
    chatSDK: OmnichannelChatSDK;
    className?: string;
}) {
    const { input, set_input, conn, isSending, set_isSending, hasMessages } =
        useGlobalStore(
            useShallow((state) => ({
                input: state.input,
                set_input: state.set_input,
                conn: state.connection,
                isSending: state.isSending,
                set_isSending: state.set_isSending,
                err: state.error,
                hasMessages: state.messages.length > 0,
            })),
        );

    const connected = conn.status === "Up";
    const sending = isSending;
    const noText = input.replace("\n", "").trim().length === 0;
    const placeholder = sending ? l["Sending"] : l["Write a message"];

    const onKeyDown: KeyboardEventHandler<HTMLTextAreaElement> = (e) => {
        if (!noText && !e.shiftKey && e.key === "Enter") {
            // pressing enter w/ text in box and w/o shift held down
            e.preventDefault();
            e.stopPropagation();
            assertConnectedAndSend();
        }
    };
    const onChange: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
        set_input(e.target.value);
        if (conn.status === "Up") {
            act.sendTypingEvent(chatSDK);
        }
    };
    const selectFile = () => {
        console.assert(
            document.getElementById("dpjWidgetUpload"),
            "no dpjWidgetUpload element",
        );
        document.getElementById("dpjWidgetUpload")?.click();
    };

    const fileChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            set_isSending(true); //TODO: set this on files?
            chatSDK
                .uploadFileAttachment(file)
                .then((msg) => {
                    console.log("file uploaded", msg);
                    act.fetchMessages(chatSDK);
                })
                .catch((e) => {
                    console.error("file upload failed", e);
                })
                .finally(() => {
                    set_isSending(false);
                });
        }
    };
    function assertConnectedAndSend() {
        console.log("assertConnectedAndSend", conn.status);
        if (conn.status === "Up") {
            console.log("connected, sending");
            return act.sendMessage(chatSDK).finally(() => {
                console.log("finally");
                focusInput();
            });
        } else if (conn.status === "Down" || conn.status === "DownWithError") {
            console.log("no connection, connecting");
            act.connect(chatSDK)
                .then(() => {
                    console.log("connected, sending");
                    return act.sendMessage(chatSDK);
                })
                .catch((e) => {
                    console.error("failed to connect", e);
                })
                .finally(() => {
                    console.log("finally");
                    focusInput();
                });
        } else {
            throw new Error("unexpected connection status");
        }
    }

    const inputDisabled = sending;
    const sendDisabled = sending || noText;
    const sendClicked = () => {
        console.log("buttonClicked", conn.status);
        assertConnectedAndSend();
    };

    const connectonWasClosed =
        (conn.status === "Down" || conn.status === "DownWithError") &&
        hasMessages;

    function newChat() {
        act.disconnect(chatSDK);
    }
    if (connectonWasClosed) {
        return (
            <>
                <div
                    className={cn(
                        "input tw-flex tw-h-fit tw-w-full tw-p-5",
                        className,
                    )}
                >
                    <Button
                        title="upload file"
                        variant={"primary"}
                        className="tw-w-full"
                        onClick={newChat}
                    >
                        {l["Start a new chat"]}
                    </Button>
                </div>
            </>
        );
    }

    let sendText = l["Send"];
    if (connected && noText) {
        sendText = l["No text"];
    } else if (!connected) {
        sendText = l["Start chatting"];
    } else if (sending) {
        sendText = l["Sending"];
    }
    return (
        <div
            className={cn(
                "input tw-flex tw-h-fit tw-w-full tw-p-5 tw-ps-0 tw-pt-2",
                className,
            )}
            onClick={focusInput}
        >
            <Button
                title="upload file"
                variant={"muted"}
                className="enabled:hover:tw-text-dpj-forest-green"
                isIcon={true}
                disabled={sending}
                onClick={selectFile}
            >
                <UploadIcon className="tw-h-6 tw-w-6" />
                <input
                    type="file"
                    name="ladda upp fil"
                    id="dpjWidgetUpload"
                    className="tw-hidden"
                    onChange={fileChanged}
                />
            </Button>
            <div className="tw-flex tw-w-full tw-rounded tw-border tw-border-solid tw-border-gray-400 tw-outline-1 tw-outline-dpj-forest-green focus-within:tw-outline sm:tw-h-[50px]">
                <div className="input-text tw-col-span-4 tw-w-full">
                    <label htmlFor="ChatInput" className="tw-sr-only">
                        {l["Chat input"]}
                    </label>

                    <textarea
                        id="ChatInput"
                        className={cn(
                            "tw-no-focus tw-h-full tw-w-full tw-resize-none tw-rounded-s tw-border-none tw-pt-[11px] tw-text-sm tw-text-dpj-industrial-grey",
                            { "py-1": !noText },
                        )}
                        rows={1}
                        disabled={inputDisabled}
                        placeholder={placeholder}
                        value={input}
                        onChange={onChange}
                        onKeyDown={onKeyDown}
                    ></textarea>
                </div>
                <label htmlFor="Send" className="tw-sr-only">
                    {sendText}
                </label>
                <button
                    title={l["Send message"]}
                    type="button"
                    onClick={sendClicked}
                    disabled={sendDisabled}
                    className={cn("group tw-m-[1px] tw-bg-transparent")}
                >
                    <PaperPlaneIcon
                        className={cn(
                            "tw-h-9 tw-w-9 -tw-rotate-[30deg] tw-rounded-full tw-bg-dpj-forest-green tw-p-1.5 tw-text-white tw-transition-colors",
                            {
                                "tw-h-10 tw-w-10 tw-bg-gray-300 tw-text-gray-50":
                                    sendDisabled && !sending,
                                "tw-animate-pulse": sending,
                            },
                        )}
                    />
                </button>
            </div>
        </div>
    );
}

export default MessageInput;
